import AsyncComponent from './AsyncComponent'

// 意见箱相关
const routers = [
  {
    path: '/customization/temperature',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-feedback' */ '../@customization/Temperature')
    ),
  },
  {
    path: '/customization/checkin',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-checkin' */ '../@customization/Checkin')
    ),
  },
  {
    path: '/customization/framework',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-framework' */ '../@customization/Framework')
    ),
  },
]

export default routers

import AsyncComponent from './AsyncComponent'

const routers = [
  {
    path: '/kpi',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: 'm-kpi' */ '../@kpi')),
  },
  {
    path: '/kpi/task/todo',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-kpi-task-todo' */ '../@kpi/task/todo')
    ),
  },
  {
    path: '/kpi/task/my',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-kpi-task-my' */ '../@kpi/task/my')
    ),
  },
  {
    path: '/kpi/task/list',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-kpi-task-list' */ '../@kpi/task/list')
    ),
  },
  {
    path: '/kpi/order/:orderId',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: 'm-kpi-order' */ '../@kpi/order')),
  },
]

export default routers

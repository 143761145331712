import AsyncComponent from './AsyncComponent'

import feedback from './feedback'
import meeting from './meeting'
import asset from './asset'
import notice from './notice'
import application from './application'
import customization from './customization'
import kpi from './kpi'
import regulation from './regulation'

const routers = [
  {
    path: '/',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: 'm-home' */ '../@home')),
  },
  {
    path: '/403',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-403' */ '../components/Forbidden')
    ),
  },
  {
    path: '/login',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: 'm-login' */ '../@login')),
  },
  {
    path: '/applies',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-uc-applies' */ '../@uc/MyApply')
    ),
  },
  {
    path: '/message',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: 'm-message' */ '../@uc/Message')),
  },
  {
    path: '/todolist',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: 'm-tasks' */ '../@task/TaskList')),
  },
  {
    path: '/todo/:id(\\d+)',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: 'm-task' */ '../@task/Task')),
  },
  {
    path: '/task/assign/:id(\\d+)',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-task-assign' */ '../@task/TaskAssign')
    ),
  },
  ...application,
  ...feedback,
  ...meeting,
  ...asset,
  ...notice,
  ...customization,
  ...kpi,
  ...regulation,
  {
    path: '/*',
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-404' */ '../components/NotFound')
    ),
  },
]

export default routers.map(route => ({
  ...route,
  path: `/m${route.path}`,
}))

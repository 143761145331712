import React from 'react'
import ReactDOM from 'react-dom'
import { toast } from 'promise-dialog'
// eslint-disable-next-line
import { AppContainer, hot } from 'react-hot-loader'

import App from './@mobile'
import * as wxsdk from './@mobile/utils/wx'
// import './@mobile/utils/lhjsbridge'
wxsdk.init()

window.message = function message(msg = '') {
  return toast({ title: msg, duration: 2000 })
}

const root = document.getElementById('app')

function render(Component) {
  if (process.env.NODE_ENV === 'development') {
    const RHLComponent = hot(module)(Component)
    ReactDOM.render(
      <AppContainer>
        <RHLComponent />
      </AppContainer>,
      root
    )
  } else {
    ReactDOM.render(<Component />, root)
  }
}

render(App)

// webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./@mobile', () => {
    // if you are using harmony modules ({modules:false})
    // render(App)
    // in all other cases - re-require App manually
    // eslint-disable-next-line
    render(require('./@mobile').default)
  })
}

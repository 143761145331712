import AsyncComponent from './AsyncComponent'

// 会议相关
const routers = [
  {
    path: '/meeting',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: 'm-meeting' */ '../@meeting')),
  },
  {
    path: '/meeting/mine',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-my-meeting' */ '../@meeting/MyMeeting')
    ),
  },
  {
    path: '/meeting/reserve/:roomId(\\d+)',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-meeting-reserve' */ '../@meeting/Reserve')
    ),
  },
  {
    path: '/meeting/room/:roomId(\\d+)',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-meeting-room' */ '../@meeting/RoomDetail')
    ),
  },
  {
    path: '/meeting/detail/:reserveId(\\d+)',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-meeting-detail' */ '../@meeting/ReserveDetail')
    ),
  },
  {
    // 扫码时需要
    path: '/meeting/:roomCode',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-meeting-scan' */ '../@meeting/RoomDetail')
    ),
  },
]

export default routers

import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'

import GlobalProvider from '../context'
import useCommonContext, { ACTION_TYPES } from '../context/common'

import { XMLConfig } from '../api/utils'

import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'

import routes from './routes'

import './styles/mobile.less'
import './styles/layout.less'
import './styles/nav.less'
import './styles/base.less'
import './styles/select.less'
import './styles/form.less'
import { getCurrentUserRight } from '../api/common'
import { QueryClient, QueryClientProvider } from 'react-query'

dayjs.locale('zh-cn')

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
    },
  },
})

const InitialData = (window as any).__INITIAL_MODEL_DATA || {}
const isDevelopment = process.env.NODE_ENV === 'development'

function fetchInitialData() {
  return Promise.all([window.fetch('/api/user/curUser', XMLConfig).then(res => res.json())])
}

export default function MobileApp() {
  const [commonState, commonDispatch] = useCommonContext({
    currentUser: InitialData.currentUser,
    userRights: InitialData.userRights || [],
  })
  const [rightsFetched, setRightsFetched] = useState(false)

  useEffect(() => {
    async function getInitialData() {
      try {
        const [userResp] = await fetchInitialData()
        if (userResp.errorCode > 0) {
          throw new Error(JSON.stringify(userResp))
        }
        const { data: user } = userResp
        commonDispatch({ type: ACTION_TYPES.UPDATE_CURRENT_USER, payload: user })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    }

    if (isDevelopment) {
      getInitialData()
    }
  }, [commonDispatch])

  useEffect(() => {
    const getRights = async () => {
      const resp = await getCurrentUserRight()
      const { data } = resp.data
      commonDispatch({ type: ACTION_TYPES.UPDATE_CURRENT_USER_RIGHTS, payload: data })
      setRightsFetched(true)
    }
    getRights()
  }, [commonDispatch])

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalProvider
        value={{
          ...commonState,
          rightsFetched,
          dispatch: commonDispatch,
        }}
      >
        <Router>{renderRoutes(routes)}</Router>
      </GlobalProvider>
    </QueryClientProvider>
  )
}

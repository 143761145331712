import AsyncComponent from './AsyncComponent'

// 资产相关
const routers = [
  {
    // 资产详情
    path: '/assets',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-asset-list' */ '../@asset/List')
    ),
  },
  {
    // 资产详情
    path: '/assets/detail/:assetId',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-asset-detail' */ '../@asset/Detail')
    ),
  },
  {
    // 资产盘点
    path: '/assets/check',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-asset-check' */ '../@asset/CheckList')
    ),
  },
  {
    // 资产盘点结果查看
    path: '/assets/check/result/:checkId',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-asset-result' */ '../@asset/CheckResult')
    ),
  },
]

export default routers

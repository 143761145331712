import AsyncComponent from './AsyncComponent'

const routers = [
  {
    path: '/application/list',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: 'm-uc-task' */ '../@home/list')),
  },
  {
    path: '/application/task',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: 'm-uc-task' */ '../@uc/MyAudit')),
  },
  {
    path: '/application/:orderId(\\d+)',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-apply-detail' */ '../@apply/FlowApply')
    ),
  },
  {
    path: '/application/new/:tableId(\\d+)',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-apply-new' */ '../@apply/FlowApply')
    ),
  },
  {
    path: '/application/data/:tableId(\\d+)/:orderId(\\d+)',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-apply-new' */ '../@apply/DataApply')
    ),
  },
  {
    path: '/application/data/:tableId(\\d+)/new',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-apply-new' */ '../@apply/DataApply')
    ),
  },
  {
    path: '/application/datalist/:tableId(\\d+)',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-apply-new' */ '../@apply/OrderPage')
    ),
  },
  {
    path: '/application/flowlist/:tableId(\\d+)',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-apply-new' */ '../@apply/OrderPage')
    ),
  },
]

export default routers

import dayjs from 'dayjs'
import * as pd from 'promise-dialog'
import qs from 'query-string'
import * as utils from '../../utils'

export const { prettysize, empty, ifEmpty, dateFormat, leftPad, promiseChain } = utils

export function str2date(str, type) {
  if (type === 'date') {
    return dayjs(str).valueOf()
  }
  if (type === 'time') {
    // 时分 -> 秒数
    const arr = str.split(':')
    return arr[0] * 3600 + arr[1] * 60
  }
  if (type === 'datetime' || type === 'datetime_h') {
    return dayjs(str).valueOf()
  }
  return null
}

export function contain(arr, cb) {
  let index = -1
  const len = arr.length
  let i = 0
  for (; i < len; i += 1) {
    if (cb(arr[i], i)) {
      index = i
      break
    }
  }
  return index > -1
}

export function calcTime(delta = 0) {
  const days = Math.floor(delta / (3600 * 1000 * 24))
  const hours = Math.floor((delta - days * 3600 * 1000 * 24) / (3600 * 1000))
  const minutes = Math.ceil((delta % (3600 * 1000)) / (1000 * 60))
  return [
    days > 0 ? `${days}天` : '',
    hours > 0 ? `${hours}小时` : '',
    minutes > 0 ? `${minutes}分钟` : '',
  ].join('')
}

export function loading(text) {
  if (text) {
    pd.loading(text)
  } else {
    pd.loaded()
  }
}

export function alert(title, duration = 2000) {
  return pd.toast({ title, duration })
}

export function success(title, duration = 2000) {
  return pd.toast({ title, duration, iconType: 'success' })
}

export function confirm(title, confirmConfig = {}) {
  const { okText = '确定', cancelText = '取消', ...rest } = confirmConfig
  return pd.confirm({ title, leftText: cancelText, rightText: okText, ...rest })
}

export function scroll(element, left, top) {
  if (!element) {
    return
  }

  if (typeof element.scrollTo === 'function') {
    element.scrollTo(left, top)
  } else {
    // 针对微信浏览器不支持scrollTo
    // eslint-disable-next-line no-param-reassign
    element.scrollLeft = left
    // eslint-disable-next-line no-param-reassign
    element.scrollTop = top
  }
}

export const promiseRecursion = promiseChain

export function prompt(text, confirmConfig = {}) {
  const { okText = '确定', cancelText = '取消', ...props } = confirmConfig
  return pd.prompt({ title: text, rightText: okText, leftText: cancelText, ...props })
}

export function modifyUrl(data) {
  const { pathname, search } = window.location
  const searchData = qs.parse(search)
  const newData = {
    ...searchData,
    ...data,
  }
  return `${pathname}?${qs.stringify(newData)}`
}

export const DateConfig = {
  year: {
    format: 'YYYY',
    caption: 'Year',
    step: 1,
  },
  month: {
    // format: 'M',
    format: value => `${value.getMonth() + 1}月`,
    caption: 'Mon',
    step: 1,
  },
  date: {
    // format: 'D',
    format: value => `${value.getDate()}日`,
    caption: 'Day',
    step: 1,
  },
}

export const DateTimeConfig = {
  ...DateConfig,
  hour: {
    format: 'hh',
    caption: 'Hour',
    step: 1,
  },
  minute: {
    format: 'mm',
    caption: 'Min',
    step: 1,
  },
}

export default {
  empty,
  ifEmpty,
  dateFormat,
  str2date,
  contain,
  leftPad,
  prettysize,
  calcTime,
  loading,
  alert,
  success,
  confirm,
  prompt,
  scroll,
  promiseRecursion,
  promiseChain,
  DateConfig,
  DateTimeConfig,
}

import AsyncComponent from './AsyncComponent'

// 公告
const routers = [
  {
    path: '/notice',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: 'm-notice' */ '../@notice')),
  },
  {
    path: '/notice/:id(\\d+)',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-notice-detail' */ '../@notice/Detail')
    ),
  },
]

export default routers

import AsyncComponent from './AsyncComponent'

const routers = [
  {
    path: '/regulation',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-regulation' */ '../@regulation')
    ),
  },
  {
    path: '/regulation/catalogue/:catalogueId/edit',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-regulation-catalogue-edit' */ '../@regulation/catalogue/edit')
    ),
  },
  {
    path: '/regulation/catalogue/:catalogueId',
    exact: true,
    component: AsyncComponent(() =>
      import(
        /* webpackChunkName: 'm-regulation-catalogue-detail' */ '../@regulation/catalogue/detail'
      )
    ),
  },
  {
    path: '/regulation/task/todo',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-regulation-task-todo' */ '../@regulation/task/todo')
    ),
  },
  {
    path: '/regulation/task/done',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-regulation-task-done' */ '../@regulation/task/done')
    ),
  },
  {
    path: '/regulation/setting/publish',
    exact: true,
    component: AsyncComponent(() =>
      import(
        /* webpackChunkName: 'm-regulation-setting-publish' */ '../@regulation/setting/publish'
      )
    ),
  },
  {
    path: '/regulation/detail',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-regulation-detail' */ '../@regulation/detail')
    ),
  },
  {
    path: '/regulation/file',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-regulation-file' */ '../@regulation/file')
    ),
  },
]

export default routers

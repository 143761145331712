import AsyncComponent from './AsyncComponent'

// 意见箱相关
const routers = [
  {
    path: '/feedback',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: 'm-feedback' */ '../@feedback')),
  },
  {
    path: '/feedback/submit',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-feedback-submit' */ '../@feedback/Submit')
    ),
  },
  {
    path: '/feedback/:id(\\d+)',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'm-feedback-detail' */ '../@feedback/Detail')
    ),
  },
]

export default routers

import wx from 'weixin-js-sdk'
import { alert, confirm } from '.'
import { wxSignMap } from '../../api/common'

export const isWechat = /micromessenger/i.test(navigator.userAgent)

function config({ appid, nonceStr, signature, timestamp }) {
  wx.config({
    beta: true, // 必须这么写，否则在微信插件有些jsapi会有问题
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId: appid, // 必填，企业微信的cropID
    timestamp, // 必填，生成签名的时间戳
    nonceStr, // 必填，生成签名的随机串
    signature, // 必填，签名，见[附录1](#11974)
    jsApiList: ['openEnterpriseChat', 'chooseImage', 'previewImage', 'downloadImage', 'scanQRCode'], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
  })

  // https://open.weixin.qq.com/connect/oauth2/authorize?appid=&redirect_uri=&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect

  wx.ready(() => {
    // console.log('wx ready')
    // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
  })

  wx.error(() => {
    // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
    // console.log(res)
  })
}

async function getWxConfig() {
  const { data } = await wxSignMap(window.location.href)
  config(data.data)
}

export function init() {
  if (isWechat) {
    getWxConfig()
  }
}

export async function talk(user) {
  const code = user.userCode || user.code
  await confirm(`是否打开与【${user.userName || user.name}】的会话`)
  if (isWechat) {
    wx.openEnterpriseChat({
      userIds: code, // 必填，参与会话的成员列表。格式为userid1;userid2;...，用分号隔开，最大限制为2000个。userid单个时为单聊，多个时为群聊。
      groupName: '', // 必填，会话名称。单聊时该参数传入空字符串""即可。
      success() {},
      fail(res) {
        if (res.errMsg.indexOf('function not exist') > -1) {
          alert('版本过低请升级', 5000)
        }
      },
    })
  }
}

export async function talkGroup(users, groupName) {
  await confirm('是否建立群聊？')
  if (isWechat) {
    wx.openEnterpriseChat({
      // 必填，参与会话的成员列表。格式为userid1;userid2;...，用分号隔开，最大限制为2000个。userid单个时为单聊，多个时为群聊。
      userIds: users.map(user => user.userCode || user.code).join(';'),
      // 必填，会话名称。单聊时该参数传入空字符串""即可。
      groupName,
      success() {},
      fail(res) {
        if (res.errMsg.indexOf('function not exist') > -1) {
          alert('版本过低请升级', 5000)
        }
      },
    })
  }
}

export function scanQRCode(scanConfig = {}) {
  const scanData = {
    needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
    scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
    // success: res => {
    //   const result = res.resultStr // 当needResult 为 1 时，扫码返回的结果
    // },
    fail(res) {
      if (res.errMsg.indexOf('function not exist') > -1) {
        alert('版本过低请升级', 5000)
      }
    },
    ...scanConfig,
  }

  if (isWechat) {
    wx.scanQRCode({
      ...scanData,
    })
  }
}
